import React from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkedAlt'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import HeaderTitle from './partials/HeaderTitle'
import ContactForm from './partials/ContactForm'
import ImgBgTwo from '../images/bg_5.png'
import ImgBgThree from '../images/bg_4.png'
import media from '../config/mediaQueries'
import data from '../data/data'

const SectionStyled = styled.section`
  background-image: url(${ImgBgTwo});
  background-repeat: repeat
  background-color: white
  position: relative
  padding: 3rem 0
  color: ${props => props.theme.primary}
`

const LeftBgStyled = styled.div`
  position: absolute
  top: 0
  left: 0
  background-image: url(${ImgBgThree});
  background-repeat: no-repeat
  background-position: top left
  width: 98px
  height: 144px
`

const BoxTitleStyled = styled.h5`
  color: ${props => props.theme.secondary}
`

const BoxContainerStyled = styled(Col)`
  ${media.between('sm', 'lg')`
    display: flex
    flex-direction: row
  `}
  
  ${media.lessThan('lg')`
    padding: 3rem 0
  `}
`

const ContactBox = ({ icon, title, description, url }) => (
  <div className='text-center'>
    <FontAwesomeIcon icon={icon} size='2x'/>
    <BoxTitleStyled className='pt-2'>{title}</BoxTitleStyled>
    <p>
      {url && (
        <a href={url}>{description}</a>
      )}
      {!url && description}
    </p>
  </div>
)

const contactBox = [
  {
    icon: faMapMarkedAlt,
    title: 'Ubicación',
    description: data.info.address,
  },
  {
    icon: faPaperPlane,
    title: 'Correo electrónico',
    description: data.info.email,
    url: `mailto:${data.info.email}`,
  },
  {
    icon: faPhone,
    title: 'Teléfono',
    description: data.info.phone,
    url: `tel:${data.info.phone}`,
  },
]

const Contact = props => (
  <SectionStyled {...props}>
    <LeftBgStyled/>
    <Container>
      <HeaderTitle preTitle='escribenos'>Contáctate con Nosotros</HeaderTitle>
      <Row className='mt-5'>
        <Col xs={{ span: 12, order: 2 }} lg={{ span: 8, order: 2 }} className='text-left'>
          <p>Completa este formulario y nos pondremos en contacto contigo lo más pronto posible. Respetamos la
            privacidad de nuestros clientes y no toleramos el envío de spam.</p>
          <ContactForm/>
        </Col>
        <BoxContainerStyled xs={{ span: 12, order: 1 }} lg={{ span: 4, order: 1 }}>
          {contactBox.map(value => (
            <Col xs={12} sm={4} lg={12} key={`@ContactBox/${value.title}`}>
              <ContactBox {...value}/>
            </Col>
          ))}
        </BoxContainerStyled>
      </Row>
    </Container>
  </SectionStyled>
)

export default Contact
