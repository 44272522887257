import React from 'react'
import styled from 'styled-components'
import {motion} from 'framer-motion'
import { ReactComponent as Separator } from '../../images/divider.svg'

const TitleStyled = styled.div`
  font-family: Oswald, sans serif;
  text-transform: uppercase
  color: ${props => props.light ? 'white' : props.theme.primary}
  font-size: 2.3rem
`

const SeparatorStyled = styled(Separator)`
  fill: ${props => props.light ? 'white' : props.theme.primary}
`

const PreTitleStyled = styled.div`
  padding-bottom: .3rem
  font-family: shorelines_script, sans serif;
  font-size: 1.3rem
  color: ${props => props.theme.secondary}
`

const variants = {
  container: {
    initial: {
      opacity: 0,
      x: -500
    },
    animate: {
      opacity: 1,
      x: 0
    }
  }
}

const HeaderTitle = ({ light, leftAlign, preTitle, children }) => (
  <motion.div
    className={`${!leftAlign ? 'text-center' : ''}`}
    initial='initial'
    animate='animate'
    variants={variants.container}
    transition={{ delay: .5 }}
  >
    <PreTitleStyled>{preTitle}</PreTitleStyled>
    <TitleStyled className='h2' light={light ? 1 : 0}>{children}</TitleStyled>
    <SeparatorStyled light={light ? 1 : 0}/>
  </motion.div>
)

export default HeaderTitle
