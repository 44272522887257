import get from 'lodash/get'
import has from 'lodash/has'
import content from '../data/data.json'

const data = {
  sizes: {
    thumbnail: 150,
    medium: 300,
    medium_large: 768,
    large: 1024,
    'post-thumbnail': 1200
  },
  get: name => get(content, name, []),
  image: {
    src: image => get(image, 'url', ''),
    alt: image => get(image, 'alt', ''),
    srcset: (image, start) => {
      const srcset = []

      if(has(image, 'sizes')) {
        Object.keys(data.sizes).forEach((size, i) => {
          if (typeof start === 'undefined' || (!!start && i >= start)) {
            if (has(image.sizes, size)) {
              const src = image.sizes[size]
              const width = data.sizes[size]

              srcset.push(`${src} ${width}w`)
            }
          }
        })
      }

      return srcset.join(', ')
    }
  }
}

export default data
