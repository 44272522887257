import React from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import get from 'lodash/get'
import filter from 'lodash/filter'
import indexOf from 'lodash/indexOf'
import slice from 'lodash/slice'
import { AnimatePresence, motion } from 'framer-motion'
import LazyLoad from 'react-lazyload'
import Carousel, { Modal, ModalGateway } from 'react-images'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import HeaderTitle from './partials/HeaderTitle'
import ImgBgFive from '../images/bg_1.jpg'
import data from '../utils/fetchData'

const SectionStyled = styled.section`
  background: url(${ImgBgFive}) repeat;
  background-position: cover;
  background-color: white
  position: relative
  padding: 3rem 0
`

const ImageStyled = styled(motion.div)`
  overflow: hidden
  text-align: center
  height: 300px
  
  img {
    margin: 0 -100%
    min-height: 300px
    max-height: 400px
    min-width: 100%
    height: auto
  }
  
  :hover {
    cursor: pointer
  }
`

const photos = data.get('images')
const categories = data.get('imageCategories')
const photosPerPage = 8

const modalStyles = {
  blanket: base => ({ ...base, zIndex: 1050 }),
  positioner: base => ({ ...base, zIndex: 1051 }),
}

class Gallery extends React.Component {
  state = {
    page: 1,
    category: null,
    listPhotos: [],
    paginatedPhotos: [],
    carouselPhotos: [],
    carouselIndex: null,
  }

  componentDidMount() {
    this.updateListPhotos()
  }

  setCarouselIndex(carouselIndex) {
    this.setState({ carouselIndex })
  }

  setPage(page) {
    this.setState({ page }, this.updateListPhotos)
  }

  setCategory(category) {
    this.setState({ category }, this.updateListPhotos)
  }

  updateListPhotos() {
    const { page, category } = this.state
    let result = photos
    let resultPaginated = []

    if (category !== null) {
      result = filter(
        result,
        o => indexOf(get(o, 'categories', []), category) !== -1,
      )
    }

    resultPaginated = slice(result, 0, photosPerPage * (page || 1))

    this.setState({
      carouselIndex: null,
      carouselPhotos: result.map(photo => ({ src: get(photo, 'image.sizes.large') })),
      listPhotos: result,
      paginatedPhotos: resultPaginated,
    })
  }

  render() {
    const { paginatedPhotos, carouselPhotos, listPhotos, page, carouselIndex } = this.state

    return (
      <SectionStyled {...this.props}>
        <LazyLoad>
          <Container>
            <HeaderTitle light preTitle='conoce mas'>Galería de fotos</HeaderTitle>

            {categories.length > 0 && (
              <Row className='mt-3 align-content-center justify-content-center'>
                <ButtonGroup>
                  <Button variant="secondary" onClick={() => this.setCategory(null)}>Todas</Button>
                  {categories.map((o, i) => (
                    <Button
                      variant="secondary"
                      onClick={() => this.setCategory(get(o, 'category', null))}
                      key={`@Gallery/CategoryButton/${get(o, 'category', i)}`}>
                      {o.category}
                    </Button>
                  ))}
                </ButtonGroup>
              </Row>
            )}

            <Row className='mt-3'>
              <AnimatePresence initial={false}>
                {paginatedPhotos.map((photo, index) => (
                  <Col as={motion.div}
                       xs={12} sm={6} lg={3}
                       className='p-0 overflow-hidden'
                       key={`@Gallery/${get(photo, 'image.ID', index)}`}
                       positionTransition
                       initial={{ opacity: 0, scale: 0.3 }}
                       animate={{ opacity: 1, scale: 1 }}
                       exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                       onClick={() => this.setCarouselIndex(index)}>
                    <ImageStyled
                      initial={{ opacity: 0, scale: 0 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ delay: (index - page * (photosPerPage - 1)) * 0.15 }}
                    >
                      <motion.div whileHover={{ scale: 1.3, rotate: 10 }}>
                        <img
                          className='lazyload'
                          alt={data.image.alt(get(photo, 'image'))}
                          data-sizes="auto"
                          data-srcset={data.image.srcset(get(photo, 'image'), 2)}
                          data-src={data.image.src(get(photo, 'image'))}
                        />
                      </motion.div>
                    </ImageStyled>
                  </Col>
                ))}
              </AnimatePresence>
            </Row>

            {listPhotos.length > paginatedPhotos.length && (
              <Col xs={12} className='text-center my-5'>
                <Button variant='secondary' onClick={() => this.setPage(page + 1)}>
                  Cargar más fotos
                </Button>
              </Col>
            )}

          </Container>

          <ModalGateway>
            {carouselIndex !== null && (
              <Modal styles={modalStyles} onClose={() => this.setCarouselIndex(null)} allowFullscreen={false}>
                <Carousel views={carouselPhotos} currentIndex={carouselIndex}/>
              </Modal>
            )}
          </ModalGateway>

        </LazyLoad>
      </SectionStyled>
    )
  }
}

export default Gallery
