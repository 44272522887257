import React from 'react'
import Navigation from '../Navigation'
import Footer from '../Footer'
import ScrollUp from './ScrollUp'

const Layout = ({ children }) => (
  <div>
    <Navigation/>
    {children}
    <Footer/>
    <ScrollUp/>
  </div>
)

export default Layout
