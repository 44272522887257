import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import styled from 'styled-components'
import Col from 'react-bootstrap/Col'
import get from 'lodash/get'
import { fadeInDown } from '../styles/animations'
import media from '../config/mediaQueries'
import data from '../utils/fetchData'

const PreTitleStyled = styled.div`
  font-family: shorelines_script, sans serif;
  font-size: 1.6rem
  color: ${props => props.theme.tertiary}
  animation: ${fadeInDown} .7s both .1s
`

const TitleStyled = styled.div`
  font-family: Oswald, sans serif;
  font-size: 4rem
  line-height: 4rem
  letter-spacing: -1px
  color: #fff
  text-transform: uppercase
  animation: ${fadeInDown} .7s both .4s

  & span {
    color: ${props => props.theme.secondary}
  }
  
  ${media.lessThan('sm')`
    font-size: 3rem
    line-height: 3rem
  `}
`

const DescriptionStyled = styled.div`
  color: #fff
  font-size: 1.2rem
  padding-top: 1rem
  animation: ${fadeInDown} .7s both .7s
  
  ${media.lessThan('sm')`
    display: none
  `}
`

const CarouselItemStyled = styled(Carousel.Item)`
  height: 600px
  background-color: #181818
  
  ${media.lessThan('lg')`
    height: 450px
  `}
`

const CarouselCaptionStyled = styled(Carousel.Caption)`
  bottom: 20% !important
  text-align: left !important
  
  ${media.between('sm', 'lg')`
      bottom: 5% !important
  `}
`

const CarouselImageStyled = styled.img`
  height: 100%
  
  ${media.lessThan('md')`
      position: relative
      left: -50%
  `}
`

const sliders = data.get('slider')

const Slider = props => (
  <Carousel fade indicators={false} {...props}>
    {sliders.map(slider => (
      <CarouselItemStyled key={`@Slider/${get(slider, 'id')}`}>
        <div className='w-100 text-center position-relative'>
          <CarouselImageStyled
            data-sizes="auto"
            src={data.image.src(get(slider, 'acf.image'))}
            data-srcset={data.image.srcset(get(slider, 'acf.image'))}
            alt={data.image.alt(get(slider, 'acf.image'))}
          />
        </div>
        <CarouselCaptionStyled>
          <Col xs={12} lg={7}>
            <PreTitleStyled>
              {get(slider, 'acf.first_line', '')}
            </PreTitleStyled>
            <TitleStyled
              dangerouslySetInnerHTML={{
                __html: get(slider, 'acf.second_line', '')
                  .replace(/\*([^*]+?)\*/g, '<span>$1</span>'),
              }}
            />
            <DescriptionStyled>
              {get(slider, 'acf.third_line', '')}
            </DescriptionStyled>
          </Col>
        </CarouselCaptionStyled>
      </CarouselItemStyled>
    ))}
  </Carousel>
)

export default Slider
