import React from 'react'
import Row from 'react-bootstrap/Row'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { Link } from 'react-router-dom'
import Logo from '../images/logo.png'
import ImgBgFooter from '../images/bg_6.jpg'
import data from '../data/data'

const RowStyled = styled(Row)`
  font-size: .8rem
  padding: 1.5rem 0
  color: #fff
  backgroundColor: ${props => props.theme.primary}
  background: url(${ImgBgFooter});
  background-size: cover
`

const LogoStyled = styled.img`
  width: 120px
  margin-bottom: 1rem
`

const LinkStyled = styled.a`
  color: #fff
  
  &:hover {
    color: ${props => props.theme.secondary}
    text-decoration: none
  }
`

const navigationLinks = [
  { text: 'Quienes somos', to: '/nosotros' },
  { text: 'Conoce nuestros productos', to: '/modelos' },
  { text: 'Nuestros principales clientes', to: '/clientes' },
  { text: 'Galería de fotos', to: '/galeria' },
  { text: 'Formulario de contacto', to: '/contacto' },
]

const Footer = () => (
  <RowStyled as='footer'>
    <Container>
      <Row>
        <Col xs={12} md={5}>
          <LogoStyled src={Logo} alt='Logo Carpaspremium.cl'/>
          <p>
            Tenemos más de 15 años de experiencia en la producción de eventos, lo que nos ha permitido desarrollar una
            carpa hecha para condiciones extremas y que cumple con los estándares de estética, funcionalidad y calidad
            que requieren nuestros clientes.
          </p>
        </Col>
        <Col xs={12} md={3}>
          <div className='h6 mb-3'>Navegación</div>
          <ul className='list-unstyled'>
            {navigationLinks.map(value => (
              <li className='mb-1' key={`@LinkFooter/${value.text}`}>
                <LinkStyled as={Link} to={value.to}>
                  <FontAwesomeIcon icon={faAngleRight} className='mr-1'/> {value.text}
                </LinkStyled>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={12} md={4}>
          <div className='h6 mb-3'>Síguenos en Redes Sociales</div>
          <LinkStyled href={data.info.url_facebook} className='mr-2'>
            <FontAwesomeIcon icon={faFacebook} size='2x'/>
          </LinkStyled>
          <LinkStyled href={data.info.url_instagram}>
            <FontAwesomeIcon icon={faInstagram} size='2x'/>
          </LinkStyled>
        </Col>
      </Row>
    </Container>
  </RowStyled>
)

export default Footer
