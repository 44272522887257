import { faCloudSunRain } from '@fortawesome/free-solid-svg-icons/faCloudSunRain'
import { faShippingFast } from '@fortawesome/free-solid-svg-icons/faShippingFast'
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons/faBoxOpen'
import { faImages } from '@fortawesome/free-solid-svg-icons/faImages'

const productFeatures = [
  {
    icon: faCloudSunRain,
    title: 'Alta resistencia',
    description: 'Carpas adaptables a todo tipo de clima y condiciones extremas'
  },
  {
    icon: faShippingFast,
    title: 'Diseño modular',
    description: 'Su sistema modular facilita el transporte y la instalación en cualquier superficie en 20 minutos por 2 personas'
  },
  {
    icon: faBoxOpen,
    title: 'Espacio reducido',
    description: 'La estructura guardada utiliza un espacio de 200x100x50 cms aproximadamente'
  },
  {
    icon: faImages,
    title: 'Personalizable',
    description: 'Tenemos un aréa de impresión para ubicar tu logo en todas las caras de la carpa'
  }
]

export default productFeatures
