import React from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import HeaderTitle from './partials/HeaderTitle'
import AboutUs from '../images/about_photo.jpg'
import ImgBgTwo from '../images/bg_8.png'
import media from '../config/mediaQueries'
import data from '../data/data'

const SectionStyled = styled.section`
  position: relative
  padding: 3rem 0
`

const BgImageStyled = styled.div`
  background: url(${ImgBgTwo}) no-repeat bottom left;
  position: absolute
  width: 496px
  height: 613px
  opacity: .2
  bottom: -150px
  left: -200px
`

const ImageStyled = styled.img`
  width: 100%
  border-radius: 20px
  position: relative
  
  ${media.lessThan('lg')`
    margin-top: 1rem
  `}
`

const About = props => (
  <SectionStyled {...props}>
    <BgImageStyled/>
    <Container>
      <Row>
        <Col xs={{ span: 12, order: 2 }} lg={6} className='d-flex align-items-center'>
          <ImageStyled data-src={AboutUs} className='lazyload' alt='Acerca de nosotros'/>
        </Col>
        <Col xs={{ span: 12, order: 1 }} lg={6} className='align-content-start'>
          <HeaderTitle preTitle='conocenos' leftAlign>Quienes somos</HeaderTitle>
          <p className='pt-3' dangerouslySetInnerHTML={{__html: data.info.about_us}}/>
        </Col>
      </Row>
    </Container>
  </SectionStyled>
)

export default About
