import React from 'react'
import Helmet from 'react-helmet'
import { scroller } from 'react-scroll'
import Slider from '../components/Slider'
import Product from '../components/Product'
import Contact from '../components/Contact'
import About from '../components/About'
import Gallery from '../components/Gallery'
import Models from '../components/Models'
import navigationLinks from '../config/navLinks'
import Clients from '../components/Clients'

class Home extends React.Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props

    if (location !== prevProps.location) {
      this.scrollToElement()
    }
  }

  scrollToElement = () => {
    const { location: { pathname } } = this.props
    const scrollOptions = {
      duration: 800,
      delay: 100,
      offset: -50,
      smooth: 'easeInOutQuint',
    }

    if (pathname) {
      const route = navigationLinks.find(o => o.pathname === pathname)

      if (route && Object.prototype.hasOwnProperty.call(route, 'anchor') && route.anchor) {
        scroller.scrollTo(route.anchor, scrollOptions)
      }
    }
  }

  render() {
    return (
      <div id='inicio'>
        <Helmet>
          <title>CarpasPremium - Empresas Promo</title>
        </Helmet>
        <Slider/>
        <About id='nosotros'/>
        <Product/>
        <Models id='modelos'/>
        <Gallery id='galeria'/>
        <Clients id='clientes'/>
        <Contact id='contacto'/>
      </div>
    )
  }
}

export default Home
