import React from 'react'
import styled from 'styled-components'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Container } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import Logo from '../images/logo.png'
import navigationLinks from '../config/navLinks'
import media from '../config/mediaQueries'

const NavbarStyled = styled(Navbar)`
  background-color: ${props => props.sticky ? props.theme.primary : 'transparent'};
  height: ${props => props.sticky ? 80 : 120}px
  transition: all .2s
  
  ${media.lessThan('lg')`
    background-color: ${props => props.theme.primary}
    height: 80px
  `}
`

const NavbarToggleStyled = styled(Navbar.Toggle)`
  color: white
`

const NavStyled = styled(Nav)`
  ${media.greaterThan('lg')`
  .nav-link {
    color: #fff !important
    padding: .5rem 1rem .5rem 1rem
    margin: 0 1rem
    position: relative
    font-size: 1.2rem
    
    ::after {
      content: " ";
      position: absolute
      left: 0
      bottom: 0
      height: 1em
      width: 100%
      border-bottom: 2px solid ${props => props.theme.tertiary}
      transition: opacity 0.2s, transform 0.2s;
      opacity: 0
      transform: scale(0,1) translateX(-60%);
    }
    
    :hover {
      cursor: pointer
      color: ${props => props.theme.secondary} !important|
      
      ::after {
        opacity: 1
        transform: scale(1) translateX(0);
        padding: 1rem .5rem
      }
    }
  }
  `}
  
  ${media.lessThan('lg')`
    background-color: ${props => props.theme.primary}
    border-radius: 0 0 10px 10px
    
    .nav-link {
      border-top: 1px solid ${props => props.theme.primaryLight}
      font-size: 1.2rem
      color: white !important
      padding: 1rem 2rem !important
    }
  `}
`

class Navigation extends React.Component {
  state = {
    sticky: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', () => this.setSticky())
  }

  setSticky() {
    const { sticky } = this.state

    if (window.scrollY > 10 && !sticky) {
      this.setState({ sticky: true })
    } else if (window.scrollY <= 10 && sticky) {
      this.setState({ sticky: false })
    }
  }

  render() {
    const { sticky } = this.state

    return (
      <NavbarStyled expand='lg' fixed='top' sticky={sticky}>
        <Container>
          <Navbar.Brand>
            <img src={Logo} alt='Logo Carpaspremium.cl' height={60}/>
          </Navbar.Brand>
          <NavbarToggleStyled aria-controls="navbar">
            <FontAwesomeIcon icon={faBars} color='white'/>
          </NavbarToggleStyled>
          <Navbar.Collapse id="navbar">
            <NavStyled className="ml-auto">
              {navigationLinks.map(value => (
                <Nav.Link as={Link} to={value.pathname} key={`@LinkHeader/${value.name}`}>{value.name}</Nav.Link>
              ))}
            </NavStyled>
          </Navbar.Collapse>
        </Container>
      </NavbarStyled>
    )
  }
}

export default withRouter(Navigation)
