const navigationLinks = [
  { name: 'Inicio', pathname: '/', anchor: 'inicio' },
  { name: 'Nosotros', pathname: '/nosotros', anchor: 'nosotros' },
  { name: 'Modelos', pathname: '/modelos', anchor: 'modelos' },
  { name: 'Galería', pathname: '/galeria', anchor: 'galeria' },
  { name: 'Clientes', pathname: '/clientes', anchor: 'clientes' },
  { name: 'Contacto', pathname: '/contacto', anchor: 'contacto' },
]

export default navigationLinks
