import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Table from 'react-bootstrap/Table'
import get from 'lodash/get'
import has from 'lodash/has'
import CarouselBootstrap from 'react-bootstrap/Carousel'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { fadeInLeft } from '../styles/animations'
import HeaderTitle from './partials/HeaderTitle'
import data from '../utils/fetchData'

const SectionStyled = styled.section`
  padding: 3rem 0
`

const ImageStyled = styled.img`
  animation: ${fadeInLeft} .7s both ${props => props.delay || 0}s
  
  &:hover {
    cursor: pointer
  }
`

const ModelBoxStyled = styled(motion.div)`
  background-color: ${props => props.active ? props.theme.secondary : '#CDCDCD'}
  color: white
  border-radius: 30px
  width: 60px
  height: 60px
  line-height: 60px
  overflow: hidden
  position: relative
  text-align: center
  margin: .3rem
  
  &:hover {
    cursor: pointer
    background-color: ${props => props.theme.secondary}
  }
`

const models = data.get('models')

let carouselProps = {
  controls: false,
  indicators: false,
  interval: null,
  keyboard: false,
  touch: false,
}

const modalStyles = {
  blanket: base => ({ ...base, zIndex: 1050 }),
  positioner: base => ({ ...base, zIndex: 1051 }),
}

const Models = props => {
  const [index, setIndex] = useState(0)
  const [direction, setDirection] = useState(null)
  const [gallery, setGallery] = useState(null)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
    setDirection(e.direction)
  }

  carouselProps = {
    ...carouselProps,
    activeIndex: index,
    direction,
    onSelect: handleSelect,
  }

  const galleryImages = []

  if (gallery !== null && has(gallery, 'model')) {
    const model = get(gallery, 'model')
    galleryImages.push({src: get(model, 'acf.main_image.sizes.large')})

    get(model, 'acf.other_images', []).forEach(image =>
      galleryImages.push({src: get(image, 'image.sizes.large')})
    )
  }

  return (
    <SectionStyled {...props}>
      <Container>
        <HeaderTitle preTitle='variedad'>Nuestros modelos</HeaderTitle>
        <Row className='pt-3'>
          <Col xs={12}>
            <div className='d-flex flex-row flex-wrap justify-content-center'>
              {models.map((model, i) => (
                <ModelBoxStyled whileHover={{ scale: 1.2 }}
                                whileTap={{ scale: 1.4, zIndex: 1500 }}
                                active={i === index}
                                key={`@Models/${model.id}`}
                                onClick={() => setIndex(i)}>
                  <motion.div whileHover={{ rotate: 25 }}>
                    {get(model, 'acf.name', '')}
                  </motion.div>
                </ModelBoxStyled>
              ))}
            </div>
          </Col>
        </Row>

        <ModalGateway>
          {gallery !== null && (
            <Modal styles={modalStyles} onClose={() => setGallery(null)} allowFullscreen={false}>
              <Carousel views={galleryImages} currentIndex={get(gallery, 'index')}/>
            </Modal>
          )}
        </ModalGateway>

        <CarouselBootstrap {...carouselProps}>
          {models.map(model => (
            <CarouselBootstrap.Item className='mt-5' key={`@Models/Model/${model.id}`}>

              <Row>
                {/* Main Image */}
                <Col xs={{ span: 12, order: 1 }} lg={{ span: 8, order: 1 }}
                     className='d-flex justify-content-center align-items-center'>
                  {has(model, 'acf.main_image.id') && (
                    <ImageStyled
                      className='mw-100 lazyload'
                      delay={.2}
                      alt={data.image.alt(get(model, 'acf.main_image', ''))}
                      data-srcset={data.image.srcset(get(model, 'acf.main_image', ''))}
                      onClick={() => setGallery({model, index: 0})}
                    />
                  )}
                </Col>

                {/* Specs */}
                <Col xs={{ span: 12, order: 3 }} lg={{ span: 4, order: 2 }} className='mt-4 mt-lg-0'>
                  <Table size='sm'>
                    <thead>
                    <tr>
                      <th>Descripción</th>
                      <th>Medida</th>
                    </tr>
                    </thead>
                    <tbody>
                    {get(model, 'acf.specs', []).map((spec, i) => (
                      <tr key={`@Model/${model.id}/Specs/${i}`}>
                        <td>{get(spec, 'description', '')}</td>
                        <td>{get(spec, 'value', '')}</td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>
                </Col>

                {/* Secondary images */}
                <Col xs={{ span: 12, order: 2 }} lg={{ order: 3 }} className='mt-5 d-flex flex-row'>
                  {get(model, 'acf.other_images', []).map((image, i) => (
                    <Col xs={4}
                         key={`@Model/${model.id}/ImageSecondary/${i}`}
                         className='d-flex justify-content-center align-items-center'>
                      <ImageStyled
                        className='w-100 lazyload'
                        delay={.2 * (i + 1)}
                        alt={data.image.alt(get(image, 'image'))}
                        data-srcset={data.image.srcset(get(image, 'image'))}
                        onClick={() => setGallery({model, index: i + 1})}
                      />
                    </Col>
                  ))}
                </Col>
              </Row>

            </CarouselBootstrap.Item>
          ))}
        </CarouselBootstrap>

      </Container>
    </SectionStyled>
  )
}

export default Models
