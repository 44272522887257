import React from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import slice from 'lodash/slice'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import HeaderTitle from './partials/HeaderTitle'
import ImageBgPattern from '../images/bg_7a.png'
import data from '../utils/fetchData'

const SectionStyled = styled.section`
  padding: 3rem 0
  background: url(${ImageBgPattern}) repeat
`

const ClientLogoStyled = styled(Col)`
   & img {
    transition: all .3s
    opacity: .7
    transform: rotate(0deg) scale(1);
  }
  
  :hover img {
    cursor: pointer
    opacity: 1
    transform: rotate(-3deg) scale(1.2);
  }
`

const clients = slice(orderBy(data.get('clients'), ['date'], ['desc']), 0, 8)

const Clients = props => (
  <SectionStyled {...props}>
    <Container>
      <HeaderTitle preTitle='Algunos de los'>Clientes que confiaron en nosotros</HeaderTitle>

      <Row>
        <Col lg={{ offset: 1, span: 10 }}>
          <Row>
        {clients.map((client, i) => (
          <ClientLogoStyled xs={6} sm={4} md={3} key={`@Clients/${i}`}>
            <img
              className='w-100 lazyload'
              data-src={data.image.src(get(client, 'logo'))}
              data-srcset={data.image.srcset(get(client, 'logo'))}
              alt={client.name}
            />
          </ClientLogoStyled>
        ))}
          </Row>
        </Col>
      </Row>

    </Container>
  </SectionStyled>
)

export default Clients
