import { generateMedia } from 'styled-media-query';

const media = generateMedia({
  xl: '1199.98px',
  lg: '991.98px',
  md: '767.98px',
  sm: '575.98px',
});

export default media
