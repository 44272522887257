import React from 'react'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

const axios = require('axios')

const FormField = ({ field, form: { touched, errors }, textarea, ...props }) => (
  <Form.Group>
    <Form.Control {...field} {...props} as={textarea ? 'textarea' : 'input'}
                  isInvalid={touched[field.name] && errors[field.name]}
                  isValid={touched[field.name] && !errors[field.name]}
    />
  </Form.Group>
)

class ContactForm extends React.Component {
  validationSchema = Yup.object().shape({
    name: Yup.string().required('Escribe tu nombre'),
    contact: Yup.string().required('Ingresa un email o teléfono').test('email or phone', 'Ingresa un email o teléfono válido',
      value =>
        Yup.string().email().isValidSync(value) ||
        Yup.string().matches(/^[+]*[(]{0,1}[0-9]{0,3}[)]{0,1}[-\s./0-9]{7,11}$/g).isValidSync(value),
    ),
    message: Yup.string().required('Escribe un mensaje'),
  })

  onSubmit = async (values, {setSubmitting, setStatus, status}) => {
    try {
      const res = await axios({
        url: '/.netlify/functions/mailgun',
        method: 'post',
        data: {
          name: values.name,
          contact: values.contact,
          message: values.message
        },
        headers: {
          'Accept': 'application/json'
        }
      });

      if (res && res.status === 200) {
        setStatus({response: 'success'});
      } else {
        console.log(res);
        setStatus({response: 'danger'});
      }
    } catch (e) {
      console.log(e);
      setSubmitting(false);
      setStatus({response: 'danger'});
    }
  }

  render() {
    return (
      <Formik initialValues={{ name: '', contact: '', message: '' }}
              validationSchema={this.validationSchema}
              onSubmit={this.onSubmit}>
        {({ handleSubmit, isSubmitting, status }) => (
          <>
            {status && status.response && (
              <Alert variant={status.response}>
                {status.response === 'success' ?
                  '¡Hemos recibido tu mensaje! nos pondremos en contacto contigo lo antes posible.' :
                  'No se ha podido enviar el mensaje, por favor intenta de nuevo'
                }
              </Alert>
            )}

            <Form noValidate onSubmit={handleSubmit}>
              <Field component={FormField} name='name' placeholder='Escribe tu nombre'/>
              <Field component={FormField} name='contact' placeholder='Escribe tu email o teléfono'/>
              <Field component={FormField} textarea rows={5} name='message' placeholder='Escribe tu mensaje'/>
              <div className='text-center'>
                <Button variant='secondary' type="submit" disabled={isSubmitting || (status && status.response === 'success')}>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isSubmitting ? (status && status.response === 'success' ? 'Mensaje Enviado' : 'Enviando...') : 'Enviar Mensaje'}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    )
  }
}

export default ContactForm
