import React from 'react'
import 'lazysizes'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { initGA, logPageView } from './utils/analytics'
import routes from './config/routes'
import theme from './styles/App.scss'
import Layout from './components/partials/Layout'

class App extends React.Component {
  componentDidMount() {
    initGA()
    logPageView()
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Layout>
            <Switch>
              {routes.map(RouteComponent =>
                React.cloneElement(RouteComponent, { key: `@Routes/${RouteComponent.props.path}` }),
              )}
            </Switch>
          </Layout>
        </Router>
      </ThemeProvider>
    )
  }
}

export default App
