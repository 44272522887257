import React from 'react'
import { animateScroll } from 'react-scroll'
import Button from 'react-bootstrap/Button'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'

const ContainerStyled = styled(motion.div)`
  margin: 0 1rem 2rem 0
  position: fixed
  bottom: 0
  right: 0
  z-index: 1040
`

const variants = {
  visible: {
    y: 0,
  },
  hidden: {
    y: 100,
  },
  hover: {
    scale: 1.3
  },
  tap: {
    scale: .8
  }
}

class ScrollUp extends React.Component {
  state = {
    active: false,
  }

  componentDidMount() {
    this.setActive()
    window.addEventListener('scroll', () => this.setActive())
  }

  setActive() {
    const { active } = this.state

    if (window.scrollY > 200 && active === false) {
      this.setState({ active: true })
    } else if (window.scrollY <= 200 && active === true) {
      this.setState({ active: false })
    }
  }

  handleClick = () => {
    animateScroll.scrollToTop({
      duration: 600,
      smooth: 'easeInOutQuint',
    })
  }

  render() {
    const { active } = this.state

    return (
      <ContainerStyled
        variants={variants}
        initial='hidden'
        whileHover='hover'
        whileTap='tap'
        animate={active ? 'visible' : 'hidden'}
      >
        <Button onClick={this.handleClick} disabled={!active} variant='secondary'>
            <FontAwesomeIcon icon={faChevronUp}/>
        </Button>
      </ContainerStyled>
    )
  }
}

export default ScrollUp
