import React from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderTitle from './partials/HeaderTitle'
import TentLayout from '../images/product_layout.png'
import ImgBgOne from '../images/bg_3.jpg'
import productFeatures from '../config/productFeatures'

const SectionStyled = styled.section`
  color: white
  background: url(${ImgBgOne}) repeat;
  background-position: cover;
  background-color: ${props => props.theme.primary}
  padding: 3rem 0
`

const TitleStyled = styled.h6`
  text-transform: uppercase
  color: ${props => props.theme.tertiary}
  padding: 1rem 0 0 0
`

const Feature = ({ n }) => (
  <div className='text-center mb-5'>
    <FontAwesomeIcon icon={productFeatures[n].icon} size='4x'/>
    <TitleStyled>{productFeatures[n].title}</TitleStyled>
    <p>{productFeatures[n].description}</p>
  </div>
)

const Product = props => (
  <SectionStyled {...props}>
    <Container>
      <HeaderTitle preTitle='Nuestro producto' light>Carpas Premium</HeaderTitle>
      <Row className='pt-5'>
        <Col xs={{ span: 12, order: 2 }} sm={{ span: 6, order: 2 }} md={{ span: 4, order: 1 }}>
          <Feature n={0}/>
          <Feature n={1}/>
        </Col>
        <Col xs={{ span: 12, order: 1 }} sm={{ span: 10, order: 1, offset: 1 }} md={{ span: 4, order: 2, offset: 0 }} className='text-center'>
          <img src={TentLayout} alt='Características de nuestras carpas' className='w-100' />
        </Col>
        <Col xs={{ span: 12, order: 3 }} sm={{ span: 6, order: 3 }} md={{ span: 4, order: 3 }}>
          <Feature n={2}/>
          <Feature n={3}/>
        </Col>
      </Row>
    </Container>
  </SectionStyled>
)

export default Product
